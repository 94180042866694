import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Hero } from "../components/hero"
import ContactForm from "../components/contactForm"

export const query = graphql`
  query ContactPageQuery {
    file(name: { eq: "bostonian-electric-electrician-working" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
const ContactPage = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Contact Us" />
      <Hero
        image={banner}
        header="Contact Bostonian Electric"
        alt="Electrician working on wires"
      />
      <section className="container my-6 mx-auto px-4 font-sans">
        <h2>Contact us for a quote</h2>
        <p>
          Get a quote for residential, commercial, or industrial electrical
          contracting.
        </p>
        <ContactForm />
      </section>
    </Layout>
  )
}
export default ContactPage
