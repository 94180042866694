import * as React from "react"

const ContactForm = () => {
  const choices = ["Residential", "Commercial", "Industrial"]

  return (
    <div className="mt-8 p-4 max-w-md shadow">
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-gray-700">Full name</span>
            <input
              name="name"
              type="text"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Phone number</span>
            <input
              name="phone"
              type="tel"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Email Address</span>
            <input
              name="email"
              type="email"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              placeholder="john@example.com"
            />
          </label>

          <div className="block">
            <span className="mb-1 text-gray-700">Services Desired</span>
            {choices.map(choice => (
              <div key={choice}>
                <label className="inline-flex items-center">
                  <input
                    name="services"
                    type="checkbox"
                    className="rounded bg-gray-200 border-transparent focus:border-transparent focus:bg-gray-200 text-gray-700 focus:ring-1 focus:ring-offset-2 focus:ring-gray-500"
                    value={choice}
                  />
                  <span className="ml-2">{choice}</span>
                </label>
              </div>
            ))}
          </div>

          <label className="block">
            <span className="text-gray-700">Subject</span>
            <input
              name="subject"
              type="text"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Message</span>
            <textarea
              name="message"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              rows="3"
            ></textarea>
          </label>

          <div>
            <button
              type="submit"
              className="text-white bg-yellow-400 hover:text-yellow-600 border border-yellow-400 px-5 py-3 rounded-full uppercase"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default ContactForm
